// 手机号
export const REG_MOBILE = /^1[3-9]{1}[0-9]{9}$/;
// 电子邮箱
export const REG_EMAIL = /^[A-Za-z0-9-_.\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
// 数字、字母密码
export const REG_PASSWORD = /^(?=.*\d)((?=.*[a-z])|(?=.*[A-Z]))[a-zA-Z0-9]+$/;
// 身份证号
export const REG_IDCARD = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/

// 正整数
export const REG_NUM = /^[1-9]+[0-9]*$/

// 保留两位小数
export const REG_DIGIT = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/

// 字符串是否为数字
export const REG_NAN = /^[0-9]+.?[0-9]*$/
