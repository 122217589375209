import api from "@/utils/fetch";

// 登录接口
export function onLogin(params) {
  return api.post("/user/login", params);
}

// 待绑定公司列表
export function userCompanyList(params) {
  return api.get("/user/company/list", params);
}

// 获取登录用户信息
export async function getUserInfo() {
  return await api.get("/user/info");
}

/**
 * 修改登录密码
 * @param params
 * @param {string} params.newPassword
 * @param {string} params.oldPassword
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function updatePassword(params) {
  return await api.post("/user/password/update", params);
}

/**
 * 重置密码发送验证码
 * @param params
 * @param {string} params.mobile
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function resetPwdSendCode(params) {
  return await api.post("/user/password/reset/code/send", params);
}

/**
 * 短信登录发送验证码
 * @param params
 * @param {string} params.mobile
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function signInSendCode(params) {
  return api.post("/user/sms/send", params);
}

/**
 * 绑定邮箱手机验证码
 * @param params
 * @param {string} params.mobile
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function verificationCode(params) {
  return api.post("/user/send/smsCode", params);
}

/**
 * 修改ess登录密码
 * @param params
 * @param {string} params.newPassword
 * @param {string} params.userId
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function essPasswordUpdate(params) {
  return api.post("/user/ess/password/update", params);
}

/**
 * 获取可绑定的公司列表(hr端)
 * @param params
 * @param {string} params.account 	账号
 * @param {string} params.smsCode 验证码
 * @param {string=} params.mobileCode 	手机号区号
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function userCompanyPcList(params) {
  return api.get("/user/company/pc/list", params);
}

/**
 * 重置密码
 * @param params
 * @param {string} params.mobile 手机号
 * @param {string} params.code 短信验证码
 * @param {string} params.password 新密码
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function resetPwd(params) {
  return await api.post("/user/password/reset", params);
}

/**
 * 获取图片验证码
 * @param params
 * @param {number} params.width
 * @param {number} params.height
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function getImageCode(params) {
  const { width, height } = params;
  return api.get(`/data/consulting/code/image/get/${width}/${height}`, params);
}

/**
 * 企业用户注册
 * @param params
 * @param {string} params.appId appId
 * @param {string} params.contact 手机号码
 * @param {string} params.company 企业名称
 * @param {string} params.name 您的姓名
 * @param {string} params.remark 您的需求
 * @param {string} params.code 验证码
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function submitContact(params) {
  return api.post("/data/consulting/add", params);
}
