import api from "@/utils/fetch";
import { queryUrlFormatter } from "@/utils/tools";

/**
 * 实时中奖信息
 * @param params
 * @param {string} params.activityId 活动id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *, xhr: XMLHttpRequest}>}
 */
export function awards(params) {
  return api.get("/activity/lucky-draw/awards", params);
}

/**
 * 基本信息
 * @param params
 * @param {string} params.activityId 活动id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *, xhr: XMLHttpRequest}>}
 */
export function info(params) {
  return api.get("/activity/lucky-draw/info", params);
}

/**
 * 兑换奖品
 * @param params
 * @param {string} params.activityId 活动id
 * @param {string=} params.addressCity 收货地址市（仅限实物奖品）
 * @param {string=} params.addressDetail 收货详细地址（仅限实物奖品）
 * @param {string=} params.addressDistrict 收货地址区（仅限实物奖品）
 * @param {string=} params.addressProvince 收货地址省（仅限实物奖品）
 * @param {string} params.mobileNumber 手机号
 * @param {string=} params.name 姓名（仅限实物奖品）
 * @param {string} params.prizeId 奖品ID
 * @param {string} params.drawId 兑奖id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *, xhr: XMLHttpRequest}>}
 */
export function redeem(params) {
  let url = "/activity/lucky-draw/prize/redeem";
  // url = queryUrlFormatter(url, {
  //   prizeId: params.prizeId,
  //   activityId: params.activityId,
  // });
  return api.post(url, params);
}

/**
 * 抽奖
 * @param params
 * @param {string} params.activityId 活动id
 * @param {string} params.poolId 奖池id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *, xhr: XMLHttpRequest}>}
 */
export function start(params) {
  let url = "/activity/lucky-draw/start";
  url = queryUrlFormatter(url, params);
  return api.post(url, {});
}

/**
 * 用户剩余可用积分
 * @param params
 * @param {string} params.activityId 活动id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *, xhr: XMLHttpRequest}>}
 */
export function remaining(params) {
  return api.get("/activity/lucky-draw/user/points/remaining", params);
}

/**
 * 查询积分记录
 * @param params
 * @param {string} params.activityId 活动id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *, xhr: XMLHttpRequest}>}
 */
export function points(params) {
  return api.get("/activity/lucky-draw/user/record/points", params);
}

/**
 * 查询中奖奖品
 * @param params
 * @param {string} params.activityId 活动id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *, xhr: XMLHttpRequest}>}
 */
export function prize(params) {
  return api.get("/activity/lucky-draw/user/record/prize", params);
}

/**
 * 更新背调订单状态（无返回值）
 * 条件：
 * 用户参与了活动
 * 接口调用时机：
 * 访问首页时
 * 访问活动首页时
 * 查询积分记录时
 * 领取奖品
 * 手动刷新剩余积分时
 * @param params
 * @param {string} params.activityId 活动id
 * @return {Promise<{flag: (0|1|2), msg: string, data: *, xhr: XMLHttpRequest}>}
 */
export function updateState(params) {
  return api.get("/activity/lucky-draw/task/update-state/rc-order", params);
}
