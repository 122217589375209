import api from "@/utils/fetch";
import { queryUrlFormatter } from "@/utils/tools";

/**
 * 获取公司余额
 * @param params
 * @param {string} params.companyId
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function getCompanyPoints(params) {
  return api.get("/company/points/get", params);
}
// 首页卡片数据
export function getStatistics(params) {
  return api.get("/company/home/platform/statistics/get", params);
}


// 下载模板
export function getDataTemplate(params) {
  return api.download("/interview/template", params, { method: 'GET' });
}

//  批量导入到场
export function importInterview(params) {
  return api.post("/interview/importInterview", params);
}

//  保存批量导入到场
export function saveImportInterview(params) {
  let url = "/interview/saveImportInterview";
  url = queryUrlFormatter(url, params);
  return api.post(url, params);
}

// HR成长记录
export function getHRNotes(params) {
  return api.get("/user/points/notes", params);
}
// 易查记录
export function getEcheckRecord(params) {
  return api.get("/echeck/order/list", params);
}
// 全部候选人列表
export function getCandidateList(params) {
  return api.get("/candidate/list", params);
}
// 导出到场记录
export function exportInterview(params) {
  return api.download('/interview/exportInterview', params, { method: 'GET' })
}

// 导出候选人
export function candidateExport(params) {
  return api.download("/candidate/export", params, { method: "GET" });
}

// 获取到场导出短信验证码
export function exportInterviewVerifyCode(params) {
  return api.get("/interview/exportInterviewVerifyCode", params);
}

// 发送导出验证码
export function exportSendCode(params) {
  return api.post("/rc/order/list-page/export/send-code", params);
}

// 获取导出手机号短信验证状态

export function mobileVerifyStatus(params) {
  return api.get("/commonMgt/mobileVerifyStatus", params);
}

//获取候选人资料
export function getCandidateFiles(params) {
  return api.get("/commonMgt/candidateFiles", params);
}


//获取报告详情
export function taskModuleRecord(params) {
  return api.get("/commonMgt/taskModuleRecord", params);
}

// 获取背调hr
export function listHr(params) {
  return api.get("/commonMgt/hr/list", params);
}

// 获取客服信息
export function customerService(params) {
  return api.get("/company/customerService", params);
}

// 根据id获取面试记录信息
export function getCandidateByIds(params) {
  return api.get("/interview/getByIds", params);
}

// 提交任务单变更(撤销核实)
export function changeTask(params) {
  return api.post("/entrust/changeTask", params);
}

// 获取智薪登录地址
export function getSmartSalary(params) {
  return api.post("/eco/smart-salary/get-login-url", params);
}

