export const cookies = {
  // 登录凭证(账号)
  EC_TOKEN: "",
  // 记住登录状态 1=记住
  EC_REMEMBER: "0",
  // 注册提交 appid
  EC_APP_ID: "waiting-for-valid-value",
};

export const COOKIE_EXPIRES = 7;

export function clearCookies() {
  cookies.EC_TOKEN = "";
}
