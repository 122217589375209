<template>
  <a-config-provider :locale="antdI18n">
    <div id="app" :class="mobileClass">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import { REG_EXCLUDE_ROUTE } from "@/router";

export default {
  name: "App",
  props: {
    antdI18n: Object,
  },
  data() {
    return {};
  },
  computed: {
    mobileClass () {
      return this.$route.name === "mobile-sign-in" ? "mobile-width" : ""
    }
  },
  methods: {
    async init() {
      // 同步操作指引数据store与本地存储
      this.$store.dispatch("guidance/getList");
    },
  },
  created() {
    if (REG_EXCLUDE_ROUTE.test(window.location.pathname)) {
      return;
    }
    // 初始化
    this.init();
  },
};
</script>

<style lang="less">
@import "@/styles/reboot.less";
@import "ant-design-vue/dist/antd.less";
@import "@/styles/theme.less";
@import "@/assets/iconfont/iconfont.less";

.ec-app-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-width {
  @media only screen and (max-width: 1200px) {
    min-width: auto !important;
   }
}

#app {
  height: 100%;
  width: 100%;
  min-width: 1200px;
  .ant-table-pagination.ant-pagination {
    background: #fafafa;
    margin: 0;
    padding: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .ant-table.ant-table-empty {
    .ant-table-placeholder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 0;
      padding: 0;

      .ant-empty-normal {
        margin: 15px 0;
      }
    }
    .ant-table-body {
      min-height: 220px;
    }
  }

  .ec-table-wrapper {
    border-color: @border-color-base;

    .ant-table-bordered .ant-table-body {
      overflow: auto;

      > table {
        border: 0;
      }
    }
  }

  .ec-report-report-level {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &-绿盾,
    &-2211,
    &-Green {
      background-image: url("./assets/images/dp/dp_6.png");
    }
    &-红盾,
    &-2209,
    &-Red {
      background-image: url("./assets/images/dp/dp_1.png");
    }
    &-黄盾,
    &-2210,
    &-Yellow {
      background-image: url("./assets/images/dp/dp_3.png");
    }
    &-蓝盾,
    &-Blue {
      background-image: url("./assets/images/dp/dp_10.png");
    }
  }
}
</style>
