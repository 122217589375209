// IE 下解决input清空属性失效问题
import {Input} from "ant-design-vue";

export function makeClearInput () {
    if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        Input.methods.handleReset = function () {
            this.stateValue = ''
            this.$emit('change.value', '')
        }
    }
}

// 判断当前环境是否支持placeholder属性
export function placeholderSupport() {
    return 'placeholder' in document.createElement('input');
}

