import NotFound from "@/components/NotFound.vue";

const User = () =>
  import(/* webpackChunkName: "user" */ "@/pages/user/Index.vue");
const SignIn = () => {
  if (
    process.env.VUE_APP_CLIENT === "hrmany" ||
    /hrmany\.com$/.test(window.location.hostname)
  ) {
    return import(
      /* webpackChunkName: "user" */ "@/pages/user/SignInHrmany.vue"
    );
  }
  return import(/* webpackChunkName: "user" */ "@/pages/user/SignInCss.vue");
};

//ESS一键登录
const SignInByEss = () => {
  return import("@/pages/user/SignInByEss.vue");
};

// 移动端首页
const MobileSignIn = () => {
  if (
    process.env.VUE_APP_CLIENT === "hrmany" ||
    /hrmany\.com$/.test(window.location.hostname)
  ) {
    return import(
      /* webpackChunkName: "user" */ "@/pages/user/SignInMobileHrmany.vue"
    );
  }
  return import(/* webpackChunkName: "user" */ "@/pages/user/SignInMobileCss.vue");
};

const SignUp = () => import(/* webpackChunkName: "user" */ "@/pages/user/SignUp.vue");

export default {
  path: "/user",
  component: User,
  children: [
    {
      path: "",
      name: "user",
      redirect: "sign-in"
    },
    {
      path: "sign-in",
      name: "sign-in",
      component: SignIn
    },
    {
      path: "sign-in-by-code",
      name: "sign-in-by-code",
      component: SignInByEss
    },
    {
      path: "sign-in/wap",
      name: "mobile-sign-in",
      component: MobileSignIn
    },
    {
      path: "sign-up",
      name: "sign-up",
      component: SignUp
    },
    {
      path: "notfound",
      name: "NotFound",
      component: NotFound,
      meta: {
        hideFoot: true
      }
    }
  ]
};
