import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "@/lang/en";
import axios from "axios";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en", // 语言环境
  fallbackLocale: "en",
});
// 预设语言环境信息
i18n.setLocaleMessage("en", messages);

const loadedLanguages = ["en"]; // 预装默认语言

async function setI18nLanguage(lang, store) {
  const { langList } = store.state;

  i18n.locale = lang;
  // 去除国际化警告
  i18n.silentTranslationWarn = true
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  document.querySelector("html > body").className = "ec-lang-" + lang;

  const curr = langList.find((it) => it.key === lang);
  // antd ui
  const antdI18n = await import(
    `ant-design-vue/lib/locale-provider/${curr.antd}`
  );
  // moment
  if (curr.moment !== "en") {
    await import(`moment/locale/${curr.moment}`);
  }

  return { i18n, antdI18n: antdI18n.default, momentI18n: curr.MOMENT };
}

export function loadLanguageAsync(lang, store) {
  console.log("App Initial...");
  // 如果语言相同
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang, store));
  }

  // 如果语言已经加载
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang, store));
  }

  // 如果尚未加载语言
  return import(
    /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.js`
  ).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang, store);
  });
}
