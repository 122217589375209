import Vue from "vue";
import router from "./routes";
import store from "../store";
import { cookies } from "@/config";
import { showMsg } from "@/utils";
import { isWap } from "@/utils/tools"; 

// 跳过验证登录权限的路由
export const REG_EXCLUDE_ROUTE = /^\/user/;

router.beforeEach((to, from, next) => {
  let isExclude = false;
  const loggedCookie = cookies.EC_TOKEN;

  const isLoggedIn = !!loggedCookie;

  if (REG_EXCLUDE_ROUTE.test(to.path)) isExclude = true;

  if (!isExclude && !isLoggedIn) {
    showMsg({
      flag: 21,
      msg: Vue.prototype.$t("showMsg.unAuthorized")
    });
    if (isWap) {
      next("/user/sign-in/wap");
      return;
    }
    next({
      name: "sign-in",
      replace: true,
      query: {
        ...to.query,
        from: to.path,
      }
    });
    return;
  }
  if (to.path === '/user/sign-in') {
    if (isWap) {
      next("/user/sign-in/wap");
      return;
    }
    next();
    return;
  }
  if (to.path === '/user/sign-in/wap') {
    if (!isWap) {
      next("/user/sign-in");
      return;
    }
    next();
    return;
  }


  // 权限守卫
  // 二级页面渲染一级页面的菜单
  // 所以只需要判断以及页面的路径即可 无须在意参数的问题
  const { path, meta = {} } = to;
  // 菜单在根组件渲染前(main.js)就拿到了 所以这里可以放心使用
  // 判断所要请求的路径是否在权限列表中
  // 不在不允许跳转
  // 主要用于防止用户直接输入url查看权限控制页面
  // 空白页、登录页忽略判断
  let nextUrl = meta.menu || path;
  if (nextUrl === "/" || REG_EXCLUDE_ROUTE.test(nextUrl)) {
    next();
    return;
  }
  const auth = store.state.home.menuList.some(it => {
    return it.href === nextUrl;
  });
  if (!auth) {
    showMsg({
      flag: 21,
      msg: Vue.prototype.$t("showMsg.unPermission")
    });
    // 跳转到404页面
    next({ name: "NotFound", replace: true });
    return;
  }

  next();
});

// 切换路由后
router.afterEach(to => {
  if (REG_EXCLUDE_ROUTE.test(to.path)) return;
  const renderPath = to.meta?.menu || to.path;
  // 菜单反向渲染
  store.commit("home/onMenu", {
    value: renderPath,
    useKeyName: "href",
    success: () => {},
    fail: msg => {
      console.log(msg);
    }
  });
});

export default router;
