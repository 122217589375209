// 操作指引

import moment from "moment";
import { getType } from "@/utils/tools";

export const GUIDANCE_KEY = (id) => `EC_GUIDANCE_LIST_${id}`;

export default {
  namespaced: true,
  state: {
    // 操作指引列表
    list: [
      // 候选人管理页面-易查和委托合并
      {
        key: "1",
        name: "CddManage",
        read: false,
        expires: moment("2024-06-01 00:00:00"),
      },
    ],
  },
  mutations: {
    setList(state, action) {
      state.list = action;
    },
    setRead(state, action) {
      if (!action || !action.key) return;
      const currIdx = state.list.findIndex((it) => it.key === action.key);
      if (currIdx < 0) return;
      state.list[currIdx].read = true;
      // 更新本地存储
      window.localStorage.setItem(GUIDANCE_KEY(action.userId), JSON.stringify(state.list));
    }
  },
  getters: {},
  actions: {
    // 获取操作指引列表
    getList({ rootState, state, commit }) {
      // 从本地存储读取持久化数据
      const local = window.localStorage.getItem(GUIDANCE_KEY(rootState.user.info.userId));
      let json;
      try {
        json = JSON.parse(local);
      } catch(err) {
        console.log(err);
      }
      // 本地数据被破坏或者本地存储没有 将state值存储到本地存储中
      if (!json || getType(json) !== "array") {
        window.localStorage.setItem(GUIDANCE_KEY(rootState.user.info.userId), JSON.stringify(state.list));
        return;
      }
      // 本地存储数据有效 更新state
      commit("setList", json);
    },
    // 获取指定的操作指引是否已读
    getRead({ rootState, state, commit }, params) {
      return new Promise((resolve) => {
        // 未传入key返回已读
        if (!params || !params.key) {
          resolve(true);
          return;
        }
        // 从本地存储读取持久化数据
        const local = window.localStorage.getItem(GUIDANCE_KEY(rootState.user.info.userId));
        let json;
        try {
          json = JSON.parse(local);
        } catch(err) {
          console.log(err);
        }
        // 本地数据被破坏或者本地存储没有 尝试从state读取
        if (!json || getType(json) !== "array") {
          const curr = state.list.find((it) => it.key === params.key);
          // state也没有指定的key 返回已读
          if (!curr) {
            resolve(true);
            return;
          }
          // state中存在 判断是否已读
          if (curr.read) {
            resolve(true);
            return;
          }
          // state中存在 判断是否过期
          if (curr.expires && moment().isAfter(moment(curr.expires), "seconds")) {
            resolve(true);
            return;
          }
          // 未读且未过期
          resolve(false);
          return;
        }
        // 本地存储有值 更新state并返回相应的已读状态
        commit("setList", json);
        const curr = json.find((it) => it.key === params.key);
        // 没有指定的key 返回已读
        if (!curr) {
          resolve(true);
          return;
        }
        // 存在 判断是否已读
        if (curr.read) {
          resolve(true);
          return;
        }
        // 存在 判断是否过期
        if (curr.expires && moment().isAfter(moment(curr.expires), "seconds")) {
          resolve(true);
          return;
        }
        // 未读且未过期
        resolve(false);
      });
    }
  },
}
