import axios from "axios";
// import utils from "axios/lib/utils";
import { cookies } from "@/config";
import {
  FETCH_CODE,
  FETCH_CONTENT_TYPE,
  FETCH_TIMEOUT,
  RES_CODE
} from "@/config/fetch";

class Api {
  constructor() {
    this.baseUrl = "/api";
  }

  /**
   * 发起 GET 请求
   * @param {string} url 请求路径|必传
   * @param {Object=} data 请求入参|可选
   * @param {Object=} options 请求自定义配置|可选
   * @returns {Promise<{flag:0|1|2,msg:string,data:any,xhr:XMLHttpRequest}>}
   */
  get(url, data, options = {}) {
    const params = this._formatRequestParams(url, "GET", data, options);
    return this._request(params);
  }

  /**
   * 发起 POST 请求
   * @param {string} url 请求路径|必传
   * @param {Object=} data 请求入参|可选
   * @param {Object=} options 请求自定义配置|可选
   * @param {Object=} postUrlParams POST请求时的URL参数|可选
   * @returns {Promise<{flag:0|1|2,msg:string,data:any,xhr:XMLHttpRequest}>}
   */
  post(url, data, options = {}, postUrlParams = {}) {
    const params = this._formatRequestParams(url, "POST", data, options, postUrlParams);
    return this._request(params);
  }

  /**
   * 下载二进制流
   * @param {string} url 请求路径|必传
   * @param {Object=} data 请求入参|可选
   * @param {Object=} options 请求自定义配置|可选
   * @returns {Promise<{flag:0|1|2,msg:string,data:any,xhr:XMLHttpRequest}>}
   */
  download(url, data, options = {}) {
    const params = this._formatRequestParams(url, options.method || "POST", data, {
      ...options,
      responseType: "arraybuffer",
    });
    const result = {
      flag: FETCH_CODE.ERROR.KEY,
      msg: FETCH_CODE.ERROR.NAME,
      data: null,
      xhr: {},
    };
    return new Promise((resolve) => {
      axios(params)
        .then((response) => {
          result.xhr = response.request;
          if (!response.data) {
            resolve(result);
            return;
          }
          result.flag = FETCH_CODE.SUCCESS.KEY;
          result.data = response.data;
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.style.cssText = "display: none;";
          link.href = url;
          let fileName = response.data.fileName;
          if (!fileName) {
            const contentDisposition = response.headers["content-disposition"] || response.headers["Content-Disposition"];
            const arr = /filename=([\s\S]*)/i.exec(contentDisposition);
            if (arr && arr[1]) {
              fileName = arr[1].replace(/"/g, "");
              fileName = decodeURIComponent(fileName);
            }
            else {
              fileName = "" + Date.now();
            }
          }
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          resolve(result);
        })
        .catch((error) => {
          let errorMessage = error.message;

          try {
            if (error.response) {
              result.xhr = error.response.request;

              const textDecoder = new TextDecoder('utf-8');
              const responseData = JSON.parse(textDecoder.decode(new Uint8Array(error.response.data)));

              if (responseData && responseData.msg) {
                errorMessage = responseData.msg;
              }
            } else if (error.request) {
              result.xhr = error.request;
            }
          } catch (err) {
            console.log(err);
          }

          result.msg = `请求失败: ${errorMessage}`;
          resolve(result);
        });
    });
  }

  /**
   * 使用formdata上传文件
   * @param {string} url 请求路径|必传
   * @param {Object=} data 请求入参|可选
   * @param {Object=} options 请求自定义配置|可选
   * @returns {Promise<{flag:0|1|2,msg:string,data:any,xhr:XMLHttpRequest}>}
   */
  upload(url, data, options) {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const params = this._formatRequestParams(url, "POST", formData, {
      ...options,
      data2Response: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return this._request(params);
  }

  /**
   * 序列化请求配置和参数
   * @param {string} url 请求路径|必传
   * @param {"GET"|"POST"} method 请求方法|必传
   * @param {Object=} data 请求入参|可选
   * @param {Object=} options 请求自定义配置|可选
   * @param {Object=} urlParams POST请求时的URL参数|可选
   * @returns {Object}
   * @private
   */
  _formatRequestParams(url, method, data, options, postUrlParams) {
    const params = {
      data,
      method,
      timeout: FETCH_TIMEOUT,
      withCredentials: true,
      responseType: "json",
      responseEncoding: "utf8",
      url: `${this.baseUrl}/${url.replace(/^\//, "")}`,
      ...options,
      headers: {
        "Authorization": cookies.EC_TOKEN,
        "Content-Type": FETCH_CONTENT_TYPE,
        ...options.headers,
      }
    };
    if (method === "GET") {
      params.params = data;
    }
    if (method === "POST" && postUrlParams) {
      params.params = postUrlParams;
    }

    return params;
  }

  /**
   * 发起请求
   * @param {Object} params 请求配置
   * @returns {Promise<{flag:0|1|2,msg:string,data:any,xhr:XMLHttpRequest}>}
   * @private
   */
  _request(params) {
    const result = {
      flag: FETCH_CODE.ERROR.KEY,
      msg: FETCH_CODE.ERROR.NAME,
      data: params.defaultData || null,
      xhr: {},
    };
    return new Promise((resolve) => {
      axios({
        ...params,
        // 如需解决 bigint 精度丢失问题 请安装包："json-bigint": "^1.0.0",
        // transformResponse: [(res) => {
        //   // 解决 bigint
        //   return JSONBigInt.parse(res);
        // }],
        // transformRequest: [(reqData) => {
        //   if (reqData && utils.isObject(reqData)) {
        //     return JSONBigInt.stringify(reqData);
        //   }
        //   return reqData;
        // }]
      })
        .then((response) => {
          result.xhr = response.request;
          const { data } = response;
          if (data.code === RES_CODE.SUCCESS.KEY) {
            result.flag = FETCH_CODE.SUCCESS.KEY;
            result.msg = FETCH_CODE.SUCCESS.NAME;
            result.data = data.data;
            if (data.tid) {
              result.tid = data.tid
            }
            if (params.data2Response) {
              result.data = data;
            }
            resolve(result);
            return;
          }
          result.flag = FETCH_CODE.WARNING.KEY;
          result.msg = data.msg || data.data?.msg || "请求失败！";
          result.data = data.data;
          if (params.data2Response) {
            result.data = data;
          }
          resolve(result);
        })
        .catch((error) => {
          result.msg = `请求失败: ${error.message}`;
          try {
            if (error.response) {
              result.xhr = error.response.request;
              const { data } = error.response;
              result.msg = data.msg || result.msg;
              result.data = data;
            } else if (error.request) {
              result.xhr = error.request;
            }
          } catch (err) {
            console.log(err);
          }
          resolve(result);
        });
    });
  }
}

export default new Api();
