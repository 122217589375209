import Vue from "vue";
import { Modal } from "ant-design-vue";
import BigNumber from "bignumber.js";
import {
  DateFormat,
  FETCH_CODE,
  PICTURE_SPLITTER,
  PRICE_DECIMALS, PRICE_RATE_BACK,
  PRICE_RATE_FRONT
} from "@/config";
import { getDic } from "@/api";
import moment from "moment/moment";

// 获取数据类型
export function getType(value) {
  return Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
}

/**
 * 格式化数字
 * @param {number|BigNumber} number 格式化的数字
 * @param {number} precision 格式化精度（保留几位小数）
 * @param {boolean} isRound 是否四舍五入-默认true
 * @returns {string} 返回一个字符串
 */
export function numberFormatter(number, precision = 0, isRound = true) {
  if (getType(precision) !== "number") precision = 0;
  const num = BigNumber(number);
  if (isRound) {
    return num.toFixed(precision);
  }
  return num.toFixed(precision, 1);
}

// 获取一个随机id
export function getFakeId() {
  const str = () => Math.random().toString(16).slice(2, 10);
  return `_local${new Date().getTime()}-${str()}-${str()}`;
}

/**
 * 确认框
 * @param title 标题
 * @param content 内容
 * @param confirm 确认函数
 * @param cancel 取消函数
 * @returns {Promise<boolean>} 返回true=确定
 */
export function confirmModal(title = Vue.prototype.$t("button.tips"), content, confirm, cancel) {
  return new Promise((resolve) => {
    Modal.confirm({
      title: title,
      content: content,
      okText: Vue.prototype.$t("button.confirm"),
      cancelText: Vue.prototype.$t("button.cancel"),
      // 取消
      onCancel: () => {
        resolve(false);
        if (typeof cancel === "function") {
          cancel();
        }
      },
      // 确定
      onOk: () => {
        resolve(true);
        if (typeof confirm === "function") {
          confirm();
        }
      },
    });
  });
}

/**
 * 金额格式化
 * @param {number|BigNumber} number 金额
 * @returns {string}
 */
export function moneyFormatter(number) {
  return numberFormatter(number, PRICE_DECIMALS);
}

/**
 * 积分、福币、金额展示格式化方法
 * @param {number} number
 * @param {boolean=} isToNumber
 * @return {string|number}
 */
export function pointsShowFormatter(number, isToNumber = false) {
  if (isToNumber) {
    return BigNumber(number).times(PRICE_RATE_FRONT).toNumber();
  }
  return BigNumber(number).times(PRICE_RATE_FRONT).toFixed(PRICE_DECIMALS);
}

/**
 * 积分、福币、金额存储格式化方法
 * @param number
 * @return {number}
 */
export function pointsSaveFormatter(number) {
  return BigNumber(number).times(PRICE_RATE_BACK).toNumber();
}

/**
 * 积分、福币、金额展示格式化方法 列表序列化 转换后的值为：show[原键名]
 * @param {{[key: string]: any}[]} list 列表
 * @param {string[]|string} keys 要转换的键 支持单个（传字符串）或者批量（传数组）
 * @return {{[key: string]: any}[]}
 */
export function listPointsShowFormatter(list, keys) {
  if (getType(keys) === "string") keys = [keys];
  return list.map((it) => {
    for (const key of keys) {
      if (it[key] !== void 0) {
        it[`${key}Show`] = pointsShowFormatter(it[key] || 0);
      }
    }
    return { ...it };
  });
}

// 商城物品图片获取第一个
export function productItemImgFormatter(pictureUrl) {
  return pictureUrl ? pictureUrl.split(PICTURE_SPLITTER)[0] : "";
}

// 商城物品价格格式化
export function productItemPriceFormatter(salesPrice) {
  salesPrice = BigNumber(salesPrice);
  salesPrice = salesPrice.times(PRICE_RATE_FRONT);
  return moneyFormatter(salesPrice);
}

// 商城物品格式化
export function productItemFormatter(item) {
  const salesPrice = item.minSalesPrice || item.skuList[0]?.salesPrice;
  return {
    ...item,
    salesPrice: productItemPriceFormatter(salesPrice),
    pictureUrl: productItemImgFormatter(item.pictureUrl),
  };
}

// query请求格式化
export function queryUrlFormatter(url, data) {
  if (!data) return url;
  // check url has ?
  const parseUrl = () => {
    if (/\?/.test(url)) {
      // is end of ?
      if (/\?$/.test(url)) {
        return url;
      }
      return url + "&";
    }
    return url + "?";
  };

  url = parseUrl();

  if (getType(data) === "String") return url + data;
  let str = "";
  const values = Object.values(data);
  Object.keys(data).forEach((key, idx) => {
    let val = values[idx];
    if (val == null) val = "";
    str += `${key}=${"" + val}&`;
  });
  str = str.replace(/&$/, "");
  return url + str;
}

/**
 * 文件大小数值格式化
 * @param {number} value 文件字节大小
 * @return {string}
 */
export function fileSizeFormatter(value, precision = 0) {
  const num = BigNumber(value);
  // 文件小于1KB
  if (num.lt(1024)) {
    return num.toString() + "B";
  }
  // 文件小于1MB
  if (num.lt(1048576)) {
    const kb = num.div(1024);
    return numberFormatter(kb, precision) + "KB";
  }
  // 文件小于1GB
  if (num.lt(1073741824)) {
    const mb = num.div(1048576);
    return numberFormatter(mb, precision) + "MB";
  }
  // 其他按照GB处理
  const gb = num.div(1073741824);
  return numberFormatter(gb, precision) + "GB";
}

/**
 * 列表字典反转name
 * @param {object[]} list
 * @param {{code:string|Array,key:string,local?:boolean}[]} dicList
 * @param {string|Array} dicList[].code 字典编码，当为本地硬编码时，为硬编码数组
 * @param {string} dicList[].key 要翻转的字段名
 * @param {boolean?} dicList[].local 是否本地硬编码
 * @return {Promise<Array>}
 */
export function listDicFormatter(list, dicList) {
  const ls = list.slice();
  const dicApis = [];
  const dic0bj = {};
  dicList.forEach((it) => {
    // 本地字典（未配置到系统中 硬编码）
    if (it.local) {
      dic0bj[it.key] = it.code;
      return;
    }
    const fn = async () => {
      const res = await getDic({ code: it.code });
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        return { key: it.key, data: res.data };
      }
      return false;
    };
    dicApis.push(fn());
  });
  return new Promise((resolve) => {
    Promise.all(dicApis).then((res) => {
      res.forEach((it) => {
        if (!it) return;
        dic0bj[it.key] = it.data;
      });

      ls.forEach((it) => {
        for (const key in dic0bj) {
          if (it[key] !== void 0) {
            const curr = dic0bj[key].find((_it) => _it.dictCode === it[key]);
            if (curr) {
              it[`${key}Name`] = curr.dictName;
            }
          }
        }
      });

      resolve(ls);
    });
  });
}

/**
 * 列表转为树形数组
 * @param {[]} list
 * @param {Object?} options
 * @param {string?} options.keyName 列表主键
 * @param {string?} options.parentKeyName 列表父级键名
 * @param {string?} options.childrenName 列表子集键名
 * @return {[]}
 */
export function list2Tree(list, options = {}) {
  const {
    keyName = "key",
    parentKeyName = "parentKey",
    childrenName = "children",
  } = options;
  const map = list.reduce((acc, o) => {
    acc[o[keyName]] = o;
    return acc;
  }, {});
  return list.reduce((acc, o) => {
    if (map[o[parentKeyName]]) {
      map[o[parentKeyName]][childrenName] = map[o[parentKeyName]][childrenName] || [];
      map[o[parentKeyName]][childrenName].push(o);
    } else {
      acc.push(o);
    }

    return acc;
  }, [])
}

/**
 * 树形数组转换为列表
 * @param {[]} tree
 * @param {Object?} options
 * @param {string?} options.childrenName 列表子集键名
 * @return {[]}
 */
export function tree2List(tree, options = {}) {
  const { childrenName = "children" } = options;
  return tree.reduce((acc, o) => {
    if (o) {
      acc.push(o);
    }
    if(o[childrenName]) {
      acc = acc.concat(tree2List(o[childrenName], options));
    }
    return acc;
  }, []);
}

// 获取form表单第一个错误信息
export function getFormModelFirstError(errors) {
  let msg;
  try {
    const ls = Object.values(errors);
    const first = ls[0];
    msg = first[0]?.message;
  } catch (err) {
    console.log(err);
  }
  return msg;
}

/**
 * 时间区间格式化
 * @param {moment.Moment[]} range 时间区间
 * @param {string} str 格式
 * @return {{start: string, end: string}}
 */
export function rangeDateFormatter(range, str = DateFormat) {
  const result = { start: "", end: "" };
  if (!range) return result;
  if (range[0]) {
    result.start = moment(range[0]).startOf("date").format(str);
  }
  if (range[1]) {
    result.end = moment(range[1]).endOf("date").format(str);
  }
  return result;
}

/**
 * 判断是否是json串
 * @param str
 * @returns {boolean}
 */
export function isJsonStr(str) {
  try {
    if (typeof JSON.parse(str) == "object") {
      return true;
    }
  } catch (e) {
    if (e === "fuck") {
      console.log(e);
    }
  }
  return false;
}

/**
 * 将文件转为base64字符串
 * @param file
 * @return {Promise<{flag:number,data:{prefix:string,value:string,full:string},error:any}>}
 */
export function file2Base64(file) {
  const result = {
    flag: 1,
    data: { prefix: "", value: "", full: "", encode: "" },
    error: null,
  };
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const base64String = event.target.result.split(",");
      result.flag = 0;
      result.data = {
        prefix: base64String[0],
        value: base64String[1],
        full: event.target.result,
        encode: encodeURIComponent(base64String[1]),
      };
      resolve(result);
    };
    reader.onerror = (error) => {
      result.error = error;
      resolve(result);
    };
    reader.readAsDataURL(file);
  });
}

/**
 * 将字典转为label value
 * @param {{dictTypeName?:string,dictType?:string,dictCode:string|number,dictName:string,dictNameEn?:string}[]} list
 * @return {{dictTypeName?:string,dictType?:string,dictCode:string|number,dictName:string,dictNameEn?:string,label:string,labelEn?:string,value:string}[]}
 */
export function dict2LabelValue(list) {
  return list.map((it) => ({
    ...it,
    label: it.dictName,
    labelEn: it.dictNameEn,
    value: it.dictCode,
  }));
}

export const isWap = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);