// 委托列表
import api from "@/utils/fetch";

export async function entrustList(params) {
  return await api.get("/entrust/list", params);
}

// 获取背调订单列表 - new
export function orderListPage(params) {
  return api.post("/rc/order/list-page/data", params);
}

// 获取背调业务线配置
export function lineRc(params) {
  return api.get("/sys/data/get/line/rc", params);
}

// 获取扫码下单的模板二维码数据
export function templateQRCode(params) {
  return api.get("/rc/order/data/template/qrcode/get", params);
}

// 获取扫码下单的易查宝二维码数据
export function quickModuleQRCode(params) {
  return api.get("/rc/order/data/quick-module/qrcode/get", params);
}

// 获取委托订单基本信息 已经废弃请勿使用
export async function getEntrustInfo(params) {
  return await api.get("/entrust/infoBeforeCreate", params);
}

// 获取委托订单套餐
export async function getEntrustTemplate(params) {
  return await api.get("/entrust/template/list", params);
}

// 获取创建订单参数(或用于绑定下拉框) 废弃请勿在使用
export function createOrderParams(params) {
  return api.get("/commonMgt/createOrderParams", params);
}

// 提交委托订单
export function submitOrderInfo(params) {
  return api.post("/entrust/submitOrderInfo", params);
}

// 列表查询条件数据源
export function orderListSearch(params) {
  return api.get("/rc/order/data/list-search", params);
}

// 获取创建背调订单参数
// companyId 存在多机构企业时用户手动选择的企业ID，指定企业ID即表示将用此企业的数据下单
export function orderBasicData(params) {
  return api.get("/rc/order/data/basic", params);
}

// 创建背调订单
export function createOrder(params, urlParams) {
  return api.post("/rc/order/create", params, {}, urlParams);
}

// 背调订单列表导出 - new
export function exportOrderListPage(params) {
  return api.download("/rc/order/list-page/export", params);
}
