/**
 * @file 全局接口
 * @author Jimmy(jihong.gu@51echeck.com)
 * @date 2023/1/4
 */

import api from "@/utils/fetch";

/**
 * 获取字典
 * @param params
 * @param {string} params.code 字典编码
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function getDic(params) {
  return api.get("/sys/dict/get", {
    dictType: params.code,
    ...params,
  });
}

/**
 * 文件下载(过时)
 * @param params
 * @param {string} params.path 文件id
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export async function getFile(params) {
  return api.get("/sys/file/download", params);
}

export async function uploadFile(params) {
  return api.post("/sys/file/uploadFile", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
