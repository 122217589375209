import Vue from "vue";
import Router from "vue-router";

import NotFound from "@/components/NotFound.vue";

Vue.use(Router);

import home from "./home";
import user from "./user";
import activity from "./activity";

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    home,
    user,
    activity,
    {
      path: "*",
      component: NotFound,
    },
  ],
});

export default router;
