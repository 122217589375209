import { getFakeId, list2Tree, tree2List } from "@/utils/tools";
import { permissionOwns, treeMenu } from "@/pages/system/api";
import { clearCookies, FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import Vue from "vue";
import router from "../../router";
import { cloneDeep } from "lodash";

// 无权限菜单（不在系统管理维护）
const UN_AUTH_MENU_LIST = [
  // 沉睡用户唤醒菜单
  {
    child: [],
    createBy: "frontend-robot@51echeck.com",
    createTime: "1970-01-01 00:00:00",
    delFlag: false,
    href: "/activity/sleep-lottery",
    id: getFakeId(),
    name: "沉睡用户唤醒活动",
    parentId: "",
    permissionId: getFakeId(),
    pinyin: "chenshuiyonghuhuanxinghuodong",
    sort: 1,
    // 平台类型 1=后台 2=HR端
    sysType: 2,
    updateBy: "frontend-robot@51echeck.com",
    updateTime: "1970-01-01 00:00:00",
    // 前端属性： 是否是隐藏菜单
    hideFlag: true
  }
];

export default {
  namespaced: true,
  state: {
    menuActive: "", // 当前打开的菜单id
    menuOpenKeys: [], // 当前打开菜单的父级id
    menuList: [],
    brandSelectList: [{ label: "易查背调", value: "易查背调" }], // 首页选择框公用数据
    buttonList: [],
  },
  mutations: {
    // 切换菜单
    onMenu(state, options = {}) {
      const {
        value,
        success = () => {},
        fail = () => {},
        useKeyName = "permissionId",
      } = options;
      const current = state.menuList.find((it) => it[useKeyName] === value);
      if (!current) {
        fail("未查询到对应的菜单！");
        return;
      }
      state.menuActive = current.permissionId;
      state.menuOpenKeys = this.getters["home/menuOpenIdList"];
      success(current);
    },
    // 设置父级展开
    onMenuOpenKeys(state, options = {}) {
      const { keys } = options;
      state.menuOpenKeys = keys;
    },
    // 设置菜单列表
    setMenuList(state, actions) {
      state.menuList = actions;
    },
    // 设置按钮列表
    setButtonList(state, actions) {
      state.buttonList = actions;
    },
  },
  getters: {
    // 得到树形结构的菜单数据
    menuTree: (state) => {
      return list2Tree(state.menuList, {
        keyName: "permissionId",
        parentKeyName: "parentId",
      });
    },
    // 得到当前打开的菜单的父级id
    menuOpenIdList: (state) => {
      const openKeys = [];
      getOpenKeys(state.menuActive, openKeys, state.menuList);
      return openKeys;
    },
    // 得到当前打开菜单的面包屑路径
    breadcrumbList: (state) => {
      const HOME = {
        path: "/",
        label: "menu.index",
        icon: "home",
      };
      if (state.menuActive === "1") {
        return [HOME];
      }
      const openMenus = [];
      getOpenKeys(state.menuActive, openMenus, state.menuList, {
        isGetObj: true,
        isCurrent: true,
      });
      openMenus.unshift(HOME);
      return openMenus;
    },
  },
  actions: {
    // 清空所有配置信息
    clearHomeInfo({ commit, state }) {
      commit("setMenuList", []);
      state.menuActive = "";
      state.menuOpenKeys = [];
    },
    // 获取菜单数据
    async getMenuList({ commit, dispatch }, payload) {
      let callback = () => {};
      if (payload && typeof payload.callback === "function") {
        callback = payload.callback;
      }
      const logOut = () => {
        clearCookies();
        dispatch("user/clearUserInfo", {}, { root: true });
        dispatch("clearHomeInfo");
        router.replace("/user/sign-in");
      };
      const res = await treeMenu();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        // 请求菜单失败
        logOut();
        callback([]);
        return;
      }
      if (!res.data || !res.data.length) {
        await showMsg({
          flag: FETCH_CODE.ERROR.KEY,
          msg: Vue.prototype.$t("menu.empty"),
        });
        logOut();
        callback([]);
        return;
      }
      // 追加本地菜单
      for (const localMenu of UN_AUTH_MENU_LIST) {
        res.data.push(cloneDeep(localMenu));
      }
      // 获取按钮权限
      const btns = await permissionOwns();
      commit("setButtonList", btns.data || []);
      // 菜单数据转列表
      const menuList = tree2List(res.data, {
        childrenName: "child",
      });
      commit("setMenuList", menuList);
      callback(menuList);
    },
  },
};
// 递归获取
function getOpenKeys(permissionId, keys, list, options = {}) {
  const { isGetObj, isCurrent } = options;
  if (!list) return;
  const current = list.find((it) => it.permissionId === permissionId);
  if (!current) return;
  if (isCurrent) {
    keys.push(current);
  }
  for (const it of list) {
    if (current.parentId === it.permissionId) {
      if (isGetObj) {
        keys.unshift(it);
      } else {
        keys.unshift(it.permissionId);
      }
      getOpenKeys(it.parentId, keys);
      break;
    }
  }
}
