import { FETCH_CODE } from "@/config";
import Vue from "vue";

/**
 * 提示消息
 * 如果弹出错误级别消息时需要等到点击确定按钮后执行操作
 * 请使用.then()或者await即可
 * @param {Object} options
 * @param {0|1|2|21} options.flag 消息级别 0=成功（消息） 1=警告（消息） 2=错误（弹窗） 21=错误（消息）
 * @param {string} options.msg 消息内容
 * @param {string=} options.title 消息标题 仅当type=2时有效
 * @returns {Promise<boolean>}
 */
export function showMsg(options = {}) {
  const { flag, msg, title } = options;

  return new Promise((resolve) => {
    if (!msg && !title) {
      resolve(false);
      return;
    }
    if (flag === FETCH_CODE.SUCCESS.KEY) {
      Vue.prototype.$message.success(msg, 2,() => {
        resolve(true);
      });
      return;
    }
    if (flag === FETCH_CODE.WARNING.KEY) {
      Vue.prototype.$message.warning(msg, 2,() => {
        resolve(true);
      });
      return;
    }
    if (flag === FETCH_CODE.ERROR_MSG.KEY) {
      Vue.prototype.$message.error(msg, 2,() => {
        resolve(true);
      });
      return;
    }
    let error = {
      title: msg,
    };
    if (title) {
      error = {
        title,
        content: msg,
      };
    }
    Vue.prototype.$error({
      ...error,
      onOk: () => {
        resolve(true);
      },
    });
  });
}

// 当数据较少时也能铺满页面
export function setTableHeight(selector, height) {
  try {
    document.querySelector(`${selector} .ant-table-body`).style.minHeight =
      height + "px";
  } catch (e) {
    // catch error
  }
}
