import api from "@/utils/fetch";
import { queryUrlFormatter } from "@/utils/tools";

// 分页获取数据字典列表
export async function getDicPage(params) {
  return api.post("/sys/dict/page", params);
}

// 保存数据字典
export async function saveDic(params) {
  return api.post("/sys/dict/sava", params);
}

// 删除数据字典
export async function delDic(params) {
  let url = "/sys/dict/del";
  url = queryUrlFormatter(url, {
    id: params.id,
  });
  return api.post(url, params);
}

// 分页获取菜单列表
export async function pageMenu(params) {
  return api.get("/sys/menu/list", params);
}

// 新增保存菜单
export async function addMenu(params) {
  return api.post("/sys/menu", params);
}

// 修改保存菜单
export async function updateMenu(params) {
  return api.post("/sys/menu/update", params);
}

// 删除菜单
export async function delMenu(params) {
  return api.post("/sys/menu/delete", params);
}

// 获取菜单列表
export async function listMenu(params) {
  return api.get("/sys/menu/allList", params);
}

// 菜单详情
export async function detailMenu(params) {
  return api.get("/sys/menu", params);
}

// 菜单详情
export async function treeMenu(params) {
  return api.get("/sys/menu/tree", params);
}

// 获取当前用户的功能权限
export function permissionOwns(params) {
  return api.get("/sys/permission/owns", params);
}

// 获取角色列表
export async function listRole(params) {
  return api.get("/sys/role/all", params);
}

// 新增保存角色
export async function addRole(params) {
  return api.post("/sys/role/add", params);
}

// 修改保存角色
export async function updateRole(params) {
  return api.post("/sys/role/update", params);
}

// 保存用户角色
export async function saveUserRoles(params) {
  return api.post("/sys/role/saveUserRoles", params);
}

// 根据用户id获取角色
export async function listByUserId(params) {
  return api.get("/sys/role/listByUserId", params);
}

// 分页获取角色列表
export async function pageRole(params) {
  return api.get("/sys/role/list", params);
}

// 删除角色
export async function delRole(params) {
  return api.post("/sys/role/delete", params);
}

// 菜单详情
export async function detailRole(params) {
  return api.get("/sys/role", params);
}

// 根据角色获取所属菜单权限
export async function getPermission(params) {
  return api.get("/sys/permission/list", params);
}

// 批量添加角色权限关系
export async function savePermission(params) {
  return api.post("/sys/permission/save", params);
}

// 分页获取用户列表
export async function pageUser(params) {
  return api.post("/sys/user/list", params);
}

// 获取用户列表
export async function listUser(params) {
  return api.post("/user/list", params);
}

// 删除用户
export async function stateUser(params) {
  return api.post("/sys/user/updateStatus", params);
}

// 新增保存用户
export async function addUser(params) {
  return api.post("/sys/user/add", params);
}

// 修改保存用户
export async function updateUser(params) {
  return api.post("/sys/user/update", params);
}

// 重置用户密码
export async function resetUserPwd(params) {
  let url = "/sys/user/resetPassword";
  url = queryUrlFormatter(url, params);
  return api.post(url, params);
}

// 获取组织列表
export async function listOrg(params) {
  return api.get("/company/dept/list", params);
}

// 新增组织
export async function addOrg(params) {
  return api.post("/company/dept/add", params);
}

// 修改组织
export async function updateOrg(params) {
  return api.post("/company/dept/update", params);
}

// 删除组织
export async function delOrg(params) {
  return api.post("/company/dept/delete", params);
}

// 获取组织树
export async function treeOrg(params) {
  return api.get("/company/dept/tree", params);
}

// 系统配置列表
export async function pageConf(params) {
  return api.post("/sys/data/page", params);
}

// 保存系统配置
export async function saveConf(params) {
  return api.post("/sys/data/sava", params);
}

// 删除系统配置
export async function delConf(params) {
  let url = "/sys/data/del";
  url = queryUrlFormatter(url, { id: params.id });
  return api.post(url, params);
}

// 公司列表
export async function pageCompany(params) {
  return api.get("/company/list", params);
}

// 公司列表
export async function listCompany(params) {
  return api.get("/company/all", params);
}

// 公司新增
export async function addCompany(params) {
  return api.post("/company/add", params);
}

// 公司修改
export async function updateCompany(params) {
  return api.post("/company/update", params);
}

// 公司详情
export async function infoCompany(params) {
  return api.get("/company/info", params);
}

// 行业列表
export async function listIndustry(params) {
  return api.get("/sys/industry/list", params);
}

// 获取所有行业数据
export function listAllIndustry(params) {
  return api.get("/sys/industry/findAll", params);
}

// 根据角色获取用户字典(type = 1:客服,2:商务)
export async function listPersonByRole(params) {
  return api.get("/user/userListByRole", params);
}

// banner 列表
export function listBanner(params) {
  return api.get("/sys/banner/get", params);
}

// banner 保存
export function saveBanner(params) {
  return api.post("/sys/banner/save", params);
}

// banner 删除
export function delBanner(params) {
  let url = "/sys/banner/del";
  url = queryUrlFormatter(url, {
    id: params.id,
  });
  return api.post(url, params);
}
// 获取企业币种兑换比例列表-分页
export function getRatePage(params) {
  return api.post("/company/points/rate/page", params);
}

// 更新企业币种兑换比例
export function updateExchangeRate(params) {
  return api.post("/company/points/rate/save", params);
}

// 删除企业币种兑换比例
export function deleteExchangeRate(params) {
  return api.post(`/company/points/rate/del/${params.id}`, params);
}

// 根据用户id获取详情 userId
export function getByUserId(params) {
  return api.get("/user/getByUserId", params);
}
