import Vue from "vue";
import { placeholderSupport } from '../compatible/IE9'
const className = 'ie-placeholder'
Vue.directive('support', {
    inserted: function (el) {
        if (placeholderSupport()) {
            return
        }
        let ele = el.getElementsByTagName('input')
       // textarea
       if (!ele.length) {
           let span = ''
           ele = el
           el.parentElement.style.width = '100%'
           el.parentElement.style.height = '100%'
           el.parentElement.style.display = 'block'
           const placeholder = ele.getAttribute("placeholder") || "请输入";
           span = document.createElement("span");
           span.className = `${className}t`;
           span.innerText = placeholder;
           ele.insertAdjacentElement("afterend",  span);
           ele.onfocus = function (event) {
               if (event.target.value) {
                   span.style.display = "none";
               }
           };
           el.oninput = function (event) {
               if (event.target.value) {
                   span.style.display = "none";
               }
           };
           ele.onblur = function (event) {
               if (!(event.target.value)) {
                   span.style.display = "inline";
               }
           };
       } else {
           // input 包括日期组件
           let spanList = []
           if (ele && ele.length) {
               for(let k = 0; k < ele.length; k++) {
                   const placeholder = ele[k].getAttribute("placeholder") || "请输入";
                   spanList[k] = document.createElement("span");
                   spanList[k].className = ele.length > 1 ? `${className}${k + 1}` : `${className}i`;
                   spanList[k].innerText = placeholder;
                   ele[k].insertAdjacentElement("afterend",  spanList[k]);
                   // 判断是input框加入div 定位盖住ie9的 x
                   if (ele.length === 1) {
                       const block = document.createElement('span')
                       console.log(ele[0].getAttribute('type'))
                       block.className = 'ie-mask'
                       // 密码框位置不一样
                       if (ele[0].getAttribute('type') === 'password') {
                           block.style.right = '55px'
                       }
                       ele[k].insertAdjacentElement("afterend",  block);
                   }
                   ele[k].onfocus = function (event) {
                       if (event.target.value) {
                           spanList[k].style.display = "none";
                       }
                   };
                   ele[k].oninput = function (event) {
                       if (event.target.value) {
                           spanList[k].style.display = "none";
                       }
                   };
                   ele[k].onblur = function (event) {
                       if (!(event.target.value)) {
                           spanList[k].style.display = "inline";
                       }
                   };
               }
           }
       }
    },
   update: function (el, binding ) {
       const ele = el.getElementsByTagName('span')
       if (ele.length) {
           // input
           if (binding.value && binding.value !== 'undefined') {
               for (let j = 0; j < ele.length; j++) {
                   if (ele[j].className.includes(className)) {
                       ele[j].style.display = 'none'
                   }
               }
           }
           if (binding.value === '' || binding.value === 'null') {
               for (let j = 0; j < ele.length; j++) {
                   if (ele[j].className.includes(className)) {
                       ele[j].style.display = 'inline-block'
                   }
               }
           }
       } else {
           // textarea
           const textarea = el
           const textList = textarea.parentElement.getElementsByTagName('span')
           if (textList.length) {
               if (binding.value) {
                   for (let j = 0; j < textList.length; j++) {
                       if (textList[j].className.includes(className)) {
                           textList[j].style.display = 'none'
                       }
                   }
               } else {
                   for (let j = 0; j < textList.length; j++) {
                       if (textList[j].className.includes(className)) {
                           textList[j].style.display = 'inline-block'
                       }
                   }
               }
           }
       }
    },
    unbind: function (el) {
        el.onfocus = el.onblur = el.oninput = null;
    }
})
