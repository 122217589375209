import Vue from "vue";
import App from "./App.vue";
import Antd from "ant-design-vue";
import moment from "moment";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

import { loadLanguageAsync } from "./lang";

import store from "./store";
import router from "./router";
import { storageInit, axiosInit, langInit } from "./utils/init";

import { makeClearInput } from "./compatible/IE9";
import "./directive/index";

Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(Viewer);

storageInit();
axiosInit();

makeClearInput();

const defaultLang = langInit(store);
loadLanguageAsync(defaultLang, store).then(
  async ({ i18n, antdI18n, momentI18n }) => {
    moment.locale(momentI18n);
    // 将i18n挂载到实例上， 使用Vue.prototype.$t()
    Vue.prototype.$t = (key, value) => i18n.t(key, value);
    await store.dispatch("user/getUserInfo");
    await store.dispatch("home/getMenuList");
    new Vue({
      i18n,
      router,
      store,
      render: h =>
        h(App, {
          props: {
            antdI18n
          }
        })
    }).$mount("#app");
  }
);
