const Index = () => {
  return import(/* webpackChunkName: "activity" */ "@/pages/activity/Index.vue");
}

const SleepLottery = () => {
  return import(/* webpackChunkName: "activity" */ "@/pages/activity/SleepLottery/Index.vue");
}

export default {
  path: "/activity",
  component: Index,
  children: [
    {
      path: "sleep-lottery",
      name: "SleepLottery",
      component: SleepLottery,
      meta: {
        title: "沉睡用户唤醒活动",
        menu: "/"
      }
    },
  ],
};
