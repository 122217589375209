// 新建委托订单-上传方式（新：2023.6）
export const BACK_ORDER_TYPE = [
  {
    key: 1,
    name: "single",
    label: "添加一位候选人",
    labelEn: "Add One Candidate",
  },
  {
    key: 2,
    name: "multi",
    label: "添加多位候选人",
    labelEn: "Add Multiple Candidates",
  },
  {
    key: 3,
    name: "batch",
    label: "批量上传候选人",
    labelEn: "Bulk Upload Candidates",
  },
];

// 新建委托订单-新建订单进度（新：2023.6）
export const BACK_ORDER_PROGRESS = [
  {
    key: 1,
    name: "添加方式",
    nameEn: "Add Mode",
    routeName: "BackOrderAdd",
  },
  {
    key: 2,
    name: "模板选择",
    nameEn: "Template Selection",
    routeName: "BackOrderAddTemplate",
  },
  {
    key: 3,
    name: "信息完善",
    nameEn: "Information Completion",
    routeName: "BackOrderAddForm",
  },
  {
    key: 4,
    name: "确认完成",
    nameEn: "Confirm Completion",
    routeName: "BackOrderAddResult",
  },
];

// 附件文件格式
export const FILE_TYPES = "7z、bmp、doc、docx、gif、jpg、jpeg、mht、mp3、mp4、pdf、png、rar、tif、tiff、txt、xls、xlsx、zip";
export const FILE_MIME_TYPES = "application/x-compressed,application/x-7z-compressed,image/bmp,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/gif,image/jpeg,image/jpg,multipart/related,audio/midi,video/quicktime,audio/mpeg,video/mp4,application/vnd.ms-outlook,application/pdf,image/png,application/x-rar-compressed,image/tiff,application/tiff,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-xpsdocument,application/x-zip-compressed,application/zip";
// 附件发送邮箱
export const FILE_SEND_EMAIL = "csd@51echeck.com";
// 核对信息类型 1=一键发送 2=核对信息
export const CHECK_CANDIDATE_TYPES = [
  { key: 1, na: "一键发送", naEn: "One-click Send" },
  { key: 2, na: "核对信息", naEn: "Verify Information" },
];
// 是否
export const YES_OR_NO = [
  { key: true, value: true, label: "是", labelEn: "Yes" },
  { key: false, value: false, label: "否", labelEn: "No" },
];
// 加急状态
export const URGENT_STATES = [
  { key: true, value: true, label: "加急", labelEn: "Urgent" },
  { key: false, value: false, label: "常规", labelEn: "Not Urgent" },
];
//候选人信息错误类型
export const CANDIDATE_INFO_WRONG_TYPE = [
  {
    type: 1,
    desc: "姓名未填写",
    descEn: "Name not provided"
  },
  {
    type: 2,
    desc: "姓名格式错误",
    descEn: "Name format error"
  },
  {
    type: 3,
    desc: "证件号未填写",
    descEn: "ID Number not provided"
  },
  {
    type: 4,
    desc: "证件号格式错误",
    descEn: "ID Number format error"
  },
  {
    type: 5,
    desc: "手机号未填写",
    descEn: "Phone Number not provided"
  },
  {
    type: 6,
    desc: "手机号格式错误",
    descEn: "Phone Number format error"
  },
  {
    type: 7,
    desc: "邮件地址未填写",
    descEn: "Email not provided"
  },
  {
    type: 8,
    desc: "邮件地址格式错误",
    descEn: "Email format error"
  }
];