// 前端请求状态分类
export const FETCH_CODE = {
  SUCCESS: {
    KEY: 0,
    NAME: "请求成功",
  },
  WARNING: {
    KEY: 1,
    NAME: "请求有警告",
  },
  ERROR: {
    KEY: 2,
    NAME: "请求错误",
  },
  ERROR_MSG: {
    KEY: 21,
    NAME: "请求错误",
  },
};

// 后端接口 code 约定
export const RES_CODE = {
  // 请求成功
  SUCCESS: {
    KEY: 200,
    NAME: "请求成功",
  },
  // 请求失败
  ERROR: {
    KEY: 500,
    NAME: "请求失败",
  },
  // 账号或者密码错误
  INVALID_USER: {
    KEY: 1001,
    NAME: "账号或密码错误",
  },
  // 未登录/认证
  UN_AUTHORIZED: {
    KEY: 401,
    NAME: "Unauthorized"
  },
  // 未绑定企业
  UN_BIND_COMPANY: {
    KEY: 1005,
    NAME: "未绑定企业",
  },
  // 未注册用户
  UN_REGISTED: {
    KEY: 1002,
    NAME: "未注册用户"
  },
  // 未验证用户
  UN_VERIFIED: {
    KEY: 1011,
    NAME: "手机号未验证"
  },
  // 密码过期
  EXPIRED_PASSWORD: {
    KEY: 1013,
    NAME: "密码过期"
  },
  // 账号临时锁定
  TEMPORARY_ACCOUNT_LOCK: {
    KEY: 1014,
    NAME: "账号临时锁定"
  }
};

export const FETCH_TIMEOUT = 100000;

export const FETCH_CONTENT_TYPE = "application/json;charset=UTF-8";
