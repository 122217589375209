<style lang="less" scoped>
@pre-cls: ~"not-found";
.@{pre-cls} {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
  text-align: center;
  &-img {
    width: 588px;
    height: 250px;
    background: url("../assets/images/404.png") no-repeat center;
    background-size: contain;
  }
  &-title {
    font-weight: bold;
    font-size: 32px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  &-text {
    font-size: 14px;
  }
  &-btn {
    margin-top: 40px;
    .left-btn {
      margin-right: 20px;
    }
  }
}
</style>

<template>
  <div class="not-found">
    <div class="not-found-img"></div>
    <div class="not-found-title">抱歉，您访问的页面出错了</div>
    <div class="not-found-text">您可能输错了网址，或该网页已删除或不存在</div>
    <div class="not-found-btn">
      <a-button class="left-btn" @click="goBack(0)">返回首页</a-button>
      <a-button type="primary" ghost @click="goBack(1)">返回上一页</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
  methods: {
    goBack(type = 0) {
      if (type) {
        this.$router.go(-1);
      } else {
        this.$router.replace("/");
      }
    }
  }
};
</script>
