import { cookies, clearCookies } from "@/config";
import axios from "axios";
import Cookies from "js-cookie";
import {
  LANGUAGES_KEY,
  COOKIE_EXPIRES,
  FETCH_TIMEOUT,
  FETCH_CONTENT_TYPE, RES_CODE,
} from "@/config";
import { showMsg } from "@/utils/index";
import Vue from "vue";
import { REG_EXCLUDE_ROUTE } from "@/router";

export function axiosInit() {
  axios.defaults.baseURL = "/";
  axios.defaults.headers.common["Content-Type"] = FETCH_CONTENT_TYPE;
  axios.defaults.timeout = FETCH_TIMEOUT;

  axios.interceptors.request.use(
    (conf) => {
      if (conf.method.toUpperCase() === "GET") {
        conf.params ? conf.params : (conf.params = {});
        Object.assign(conf.params, { ts: +new Date() });
      }
      return conf;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      try {
        const { data } = err.response;
        // 未登录清空cookie并刷新页面
        if (
          data.code === RES_CODE.UN_AUTHORIZED.KEY &&
          !REG_EXCLUDE_ROUTE.test(window.location.pathname)
        ) {
          clearCookies();
          showMsg({
            flag: 21,
            msg: Vue.prototype.$t("showMsg.unAuthorized"),
          }).then(() => {
            Vue.prototype.$router.replace({ name: "sign-in" });
          });
        }
      } catch (e) {
        console.error(e);
      }
      return Promise.reject(err);
    }
  );
}

export default axios;

export function storageInit() {
  const cookieConf = { expires: COOKIE_EXPIRES };

  for (const key in cookies) {
    // 初始化从cookie还原值
    const cookieValue = Cookies.get(key);
    if (cookieValue) {
      cookies[key] = cookieValue;
    } else {
      // 同步cookie
      Cookies.set(key, cookies[key], cookieConf);
    }

    Object.defineProperty(cookies, key, {
      get: function() {
        return Cookies.get(key);
      },
      set: function(newValue) {
        if (key === "EC_TOKEN") {
          // 不记住我不设置过期时间 - 即关闭浏览器清除
          if (cookies.EC_REMEMBER !== "1") {
            cookieConf.expires = undefined;
          }
        }
        console.log(
          `%cCookie set new value: %o, cookieConf: %o`,
          "background:#4C68F8;color:#fff;",
          newValue,
          cookieConf,
        );
        Cookies.set(key, newValue, cookieConf);
      },
      enumerable: true,
      configurable: true,
    });
  }
}

export function langInit(store) {
  const { langList } = store.state;
  let defaultLang = localStorage.getItem(LANGUAGES_KEY);
  // 用户没有设置过语言 获取默认语言
  if (!defaultLang) {
    // 服务端根据IP去下发语言标识字段
    // navigator.language 浏览器环境语言进行设定
    if (navigator.language === "zh-CN") {
      defaultLang = langList[1].key;
    } else {
      defaultLang = langList[0].key;
    }
  }
  store.commit("setLang", { value: defaultLang, isInit: true });
  return defaultLang;
}
