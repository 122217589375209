import { getUserInfo } from "@/pages/user/api";
import { FETCH_CODE } from "@/config";
import { getCompanyPoints } from "@/pages/home/api";
import defaultAvatar from "@/assets/images/home_avatar.png";
import { getType, pointsShowFormatter } from "@/utils/tools";
import { cloneDeep } from "lodash";
import { updateState } from "@/pages/activity/SleepLottery/api";

const EmptyInfo = {
  id: null,
  delFlag: false,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  userId: null,
  name: null,
  gender: false,
  mobilePhoneCode: null,
  mobilePhoneNumber: null,
  mobilePhoneNumberVerified: true,
  telephoneNumber: null,
  emailAddress: null,
  reportReceiveEmailAddress: null,
  password: null,
  passwordSalt: null,
  isDisabled: false,
  type: 2,
  headImg: null,
  currentCompany: {
    companyId: null,
    parentCompanyId: null,
    name: null,
    nameShort: null,
    nameOther: null,
    nameOtherShort: null,
    taxCode: null,
    country: null,
    province: null,
    city: null,
    district: null,
    address: null,
    typeId: 0,
    industryId: null,
    scaleId: 0,
    sourceId: 0,
    categoryId: 0,
    intro: null,
    businessUserId: null,
    serviceUserId: null
  },
  companies: null,
  roleIds: [],
  enteredLuckyDraw: [],
};
const EmptyGrowthPoints = {
  value: 0,
  shown: "0.00",
};
const EmptyPlatformConf = {
  name: null,
  logo: null,
  moneyName: null,
  tool: false,
};

// 默认展示的行业数据
export const DEFAULT_INDUSTRY = ["GDP", "CPI", "人均薪酬成本", "扩招公司占比", "离职率"];
export const DEFAULT_INDUSTRY_EN = ["GDP", "CPI", "Salary Cost Per", "Expand Recruitment", "Resign Rate"];

export default {
  namespaced: true,
  state: {
    // 登录用户基本信息
    info: cloneDeep(EmptyInfo),
    // 余额信息
    balance: [],
    // 成长积分
    growthPoints: {
      ...EmptyGrowthPoints,
    },
    // 平台配置信息
    platformConf: {
      ...EmptyPlatformConf,
    },
    // 登录用户行业数据
    industry: [],
    // 选择展示的行业数据
    selectedIndustry: [],
  },
  mutations: {
    // 修改登录用户信息
    setUserInfo(state, actions) {
      for (const key in actions) {
        state.info[key] = actions[key];
      }
    },
    // 修改余额信息
    setBalance(state, actions) {
      state.balance = actions;
    },
    // 修改平台配置信息
    setPlatformConf(state, actions) {
      for (const key in actions) {
        state.platformConf[key] = actions[key];
      }
    },
    // 修改成长积分
    setGrowthPoints(state, actions) {
      for (const key in actions) {
        state.growthPoints[key] = actions[key];
      }
    },
    // 修改登录用户行业数据
    setIndustry(state, actions) {
      state.industry = actions;
    },
    // 修改选择展示的行业数据
    setSelectedIndustry(state, actions) {
      const setLocalIndustry = (selectedIndustry) => {
        window.localStorage.setItem(`SELECTED_INDUSTRY_${actions.lang}_${state.info.userId}`, JSON.stringify(selectedIndustry));
      };
      if (!state.industry.length || !actions.selected) {
        state.selectedIndustry = [];
        setLocalIndustry([]);
        return;
      }
      const selectedList = [];
      for (const action of actions.selected) {
        for (const item of state.industry) {
          const curr = item.data.find((it) => it.name === action);
          if (curr) {
            selectedList.push(curr);
            break;
          }
        }
      }
      state.selectedIndustry = selectedList;
      setLocalIndustry(selectedList);
    },
  },
  actions: {
    // 获取当前登录用户信息
    async getUserInfo({ commit, rootState }, payload) {
      let callback = () => {};
      if (payload && typeof payload.callback === "function") {
        callback = payload.callback;
      }
      // 获取基本信息
      const res = await getUserInfo();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        commit("setUserInfo", cloneDeep(EmptyInfo));
        callback();
        return;
      }
      if (!res.data.headImg) {
        res.data.headImg = defaultAvatar;
      }
      commit("setUserInfo", res.data);
      // 获取行业数据
      try {
        const industryRes = await fetch(`/template/smartsalary_data_${rootState.lang}.json`);
        const industry = await industryRes.json();
        // 设置当前登录用户公司行业数据
        const { industryId } = res.data.currentCompany || {};
        let currentIndustry = industry.find((it) => {
          return it.idList.includes(industryId);
        });
        if (!currentIndustry) {
          currentIndustry = industry.find((it) => {
            return it.code === "ALL";
          });
        }
        commit("setIndustry", currentIndustry?.groups ?? []);
        // 从本地存储读取所选择的行业数据
        let localIndustrySelected = window.localStorage.getItem(`SELECTED_INDUSTRY_${rootState.lang}_${res.data.userId}`);
        if (localIndustrySelected) {
          localIndustrySelected = JSON.parse(localIndustrySelected);
          if (getType(localIndustrySelected) === "array") {
            localIndustrySelected = localIndustrySelected.map((it) => it.name);
          } else {
            localIndustrySelected = [...DEFAULT_INDUSTRY];
            if (rootState.lang === rootState.langList[0].key) {
              localIndustrySelected = [...DEFAULT_INDUSTRY_EN];
            }
          }
        }
        if (!localIndustrySelected || !localIndustrySelected.length) {
          localIndustrySelected = [...DEFAULT_INDUSTRY];
          if (rootState.lang === rootState.langList[0].key) {
            localIndustrySelected = [...DEFAULT_INDUSTRY_EN];
          }
        }
        commit("setSelectedIndustry", {
          selected: localIndustrySelected,
          lang: rootState.lang,
        });
      } catch (err) {
        console.log(err);
      }
      // 获取福利平台配置信息
      commit("setPlatformConf", {
        ...res.data.platformConf,
      });
      // 获取余额
      const resBalance = await getCompanyPoints({
        companyId: res.data?.currentCompany?.companyId,
      });
      if (resBalance.flag === FETCH_CODE.SUCCESS.KEY) {
        const balanceList = resBalance.data || [];
        balanceList.forEach((it) => {
          it.pointsShown = pointsShowFormatter(it.points || 0);
          it.pointsValue = pointsShowFormatter(it.points || 0, true);
          if (it.currency === "benefits_coin") {
            it.currencyName = res.data.platformConf?.moneyName;
          }
        });
        commit("setBalance", balanceList);
      }
      // 获取成长积分
      const growthPoints = 0;
      const growthPointsShown = pointsShowFormatter(growthPoints);
      commit("setGrowthPoints", {
        value: growthPoints,
        shown: growthPointsShown,
      });
      callback();
    },
    // 清空所有信息
    clearUserInfo({ commit }) {
      commit("setUserInfo", cloneDeep(EmptyInfo));
      commit("setBalance", []);
      commit("setPlatformConf", { ...EmptyGrowthPoints });
      commit("setGrowthPoints", { ...EmptyPlatformConf });
    },
    // 更新背调订单状态
    updateOrderStatus(opt, payload) {
      let activityId;
      if (payload && payload.activityId) {
        activityId = payload.activityId;
      }
      if (!activityId) {
        activityId = this.sleepLotteryActivityId;
      }
      if (!activityId) return;
      updateState({ activityId });
    }
  },
  getters: {
    // 平台余额
    cashBalance(state) {
      const balanceVal = state.balance.find((it) => it.currency === "main_coin");
      if (balanceVal) {
        return balanceVal.pointsShown;
      }
      return pointsShowFormatter(0);
    },
    cashBalanceNumber(state) {
      const balanceVal = state.balance.find((it) => it.currency === "main_coin");
      if (balanceVal) {
        return balanceVal.pointsValue;
      }
      return 0;
    },
    // 背调币
    coinBalance(state) {
      const balanceVal = state.balance.find((it) => it.currency === "back_tone_coin");
      if (balanceVal) {
        return balanceVal.pointsShown;
      }
      return pointsShowFormatter(0);
    },
    coinBalanceNumber(state) {
      const balanceVal = state.balance.find((it) => it.currency === "back_tone_coin");
      if (balanceVal) {
        return balanceVal.pointsValue;
      }
      return 0;
    },
    // 背调币名称
    backgroundCoinName(state) {
      const balanceVal = state.balance.find((it) => it.currency === "back_tone_coin");
      if (balanceVal) {
        return balanceVal.currencyName;
      }
      return "background check coin";
    },
    // 福币
    pointsBalance(state) {
      const balanceVal = state.balance.find((it) => it.currency === "benefits_coin");
      if (balanceVal) {
        return balanceVal.pointsShown;
      }
      return pointsShowFormatter(0);
    },
    pointsBalanceNumber(state) {
      const balanceVal = state.balance.find((it) => it.currency === "benefits_coin");
      if (balanceVal) {
        return balanceVal.pointsValue;
      }
      return 0;
    },
    // 福币名称
    benefitsCoinName(state) {
      const balanceVal = state.balance.find((it) => it.currency === "benefits_coin");
      if (balanceVal) {
        return balanceVal.currencyName;
      }
      return "benefits coin";
    },
    // 调研币
    surveyBalance(state) {
      const balanceVal = state.balance.find((it) => it.currency === "survey_coin");
      if (balanceVal) {
        return balanceVal.pointsShown;
      }
      return pointsShowFormatter(0);
    },
    surveyBalanceNumber(state) {
      const balanceVal = state.balance.find((it) => it.currency === "survey_coin");
      if (balanceVal) {
        return balanceVal.pointsValue;
      }
      return 0;
    },
    // 调研币名称
    surveyCoinName(state) {
      const balanceVal = state.balance.find((it) => it.currency === "survey_coin");
      if (balanceVal) {
        return balanceVal.currencyName;
      }
      return "survey coin";
    },
    // 人民币
    rmbBalance(state) {
      const balanceVal = state.balance.find((it) => it.currency === "rmb");
      if (balanceVal) {
        return balanceVal.pointsShown;
      }
      return pointsShowFormatter(0);
    },
    rmbBalanceNumber(state) {
      const balanceVal = state.balance.find((it) => it.currency === "rmb");
      if (balanceVal) {
        return balanceVal.pointsValue;
      }
      return 0;
    },
    // 账号是否存在多机构
    multipleCompany(state) {
      return state.info.companies && state.info.companies.length;
    },
    // 账号所有公司
    companyList(state) {
      const list = [{ ...state.info.currentCompany }];
      if (state.info.companies && state.info.companies.length) {
        state.info.companies.forEach((it) => {
          list.push({ ...it });
        });
      }
      return list;
    },
    // 是否显示活动banner
    sleepLotteryActivityId(state) {
      const { enteredLuckyDraw } = state.info;
      if (enteredLuckyDraw && enteredLuckyDraw.length) {
        const currentActivity = enteredLuckyDraw[0];
        if (!currentActivity) {
          return false;
        }
        const { activityId, activityInProgress } = currentActivity;
        if (!activityInProgress) {
          return false;
        }
        return activityId;
      }
      return false;
    }
  },
};
