export const LOGIN_TYPE = {
  // 手机短信验证码登录
  MOBILE_SMS_CODE: 1,
  // 账号和密码登录
  USER_PASSWORD: 2,
  // 账号和两步验证令牌登录
  USER_2FA_CODE: 3,
  // 微信
  OPEN_WECHAT: 4,
  // 企业微信
  OPEN_WECHAT_WORK: 5,
  // 钉钉
  OPEN_DINGTALK: 6,
  // 飞书
  OPEN_FEISHU: 7,
  // ESS一键登录
  ESS_ONE_KEY_LOGIN: 127,
};

export const OLD_WEBSITE = "https://css.51echeck.com";
